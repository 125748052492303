$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;

  margin-top: var(--b-space-m);
  border: toRem(1) solid var(--b-color-fill-neutral-02);
  border-radius: var(--b-border-radius-8);
  min-width: toRem(258);
  min-height: toRem(100);
  padding: var(--b-space-m);

  line-height: toRem(16);

  background-color: var(--b-color-bg-white);
  cursor: pointer;

  @media #{$fromLaptop} {
    margin-top: unset;
    min-width: toRem(358);
    min-height: unset;
    padding: var(--b-space-m);
    // Padding definido en diseño para ajustarse al borde de la izquierda
    padding-left: toRem(20);

    border-left-width: toRem(4);

    [dir='rtl'] & {
      padding-left: var(--b-space-m);
      padding-right: toRem(20);

      border-left-width: toRem(1);
      border-right-width: toRem(4);
    }
  }
}

.yourDiscountsLink {
  color: var(--b-color-secondary-link);
  @media #{$fromLaptop} {
    text-decoration: underline;
  }
}

.addDiscountLink {
  text-decoration: underline;
}

.icon {
  border-radius: var(--b-border-radius-50-percent);
  padding: var(--b-space-xxs);

  background-color: var(--b-color-fill-ocean-100);
}
