$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  top: 0;

  border-radius: var(--b-border-radius-8);
  padding-top: var(--b-space-m);

  background-color: var(--b-color-bg-light);

  @media #{$fromLaptop} {
    display: grid;
    grid-column: 1 / span 12;

    margin-bottom: var(--b-space-l);
    border: toRem(1) solid var(--b-color-fill-neutral-04);
    padding: var(--b-space-m);

    background-color: var(--b-color-bg-white);
  }

  &_standardSelected {
    @media #{$fromLaptop} {
      border: toRem(1) solid var(--b-color-fill-neutral-02);
    }
  }
}

.bestPriceTag {
  margin-top: var(--b-space-xs);
  width: fit-content;

  background-color: var(--b-color-fill-neutral-04);
  line-height: toRem(16);
  @media #{$fromLaptop} {
    margin-top: unset;
    margin-left: var(--b-space-xs);
  }
}

.sticky {
  @include fullWidthContainer;
  @include fullWidthContainerLateralPadding;
  position: sticky;
  z-index: var(--b-z-index-5);

  border: toRem(1) solid var(--b-color-fill-neutral-02);
  border-radius: unset;
  min-height: toRem(56);

  background-color: var(--b-color-bg-white);

  @media #{$fromLaptop} {
    top: 0;

    margin-bottom: unset;
    border: unset;
    border-bottom: toRem(2) solid var(--b-color-fill-ocean-10);

    transition: top 0.2s ease-in-out;
  }

  &_withOnlyRibbon{
    top: toRem(56);
    @media #{$fromLaptop} {
      top: var(--b-space-2xl);
    }
  }

  &_withOnlyHeader {
    @media #{$fromLaptop} {
      top: var(--b-space-5xl);
    }
  }

  &_withRibbonAndHeader {
    @media #{$fromLaptop} {
      top: calc(var(--b-space-2xl) + var(--b-space-5xl));
    }
  }
}
