$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/fonts';

.couponTag {
  @include font-xxs-500;

  overflow: hidden;

  background-color: var(--b-color-support-success-light);

  text-overflow: ellipsis;
}

.withoutCodeName {
  background-color: var(--b-color-tag-prices);
}
