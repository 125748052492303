$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/containers';

.container {
  display: grid;
  gap: var(--b-space-l);
  grid-template-areas:
    'mealplans'
    'rateSelector'
    'ratePrice';
  grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(3, auto);

  @media #{$fromLaptop} {
    gap: var(--b-space-l) 0;
    grid-template-areas:
      'mealplans mealplans'
      'divider divider'
      'rateSelector ratePrice';
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
  }
}

.divider {
  grid-area: divider;
}

.mealplanFilterContainer {
  grid-area: mealplans;
}

.rateSelectorContainer {
  display: grid;
  gap: var(--b-space-l);
  grid-area: rateSelector;

  @media #{$fromLaptop} {
    grid-template-columns: 1fr 1fr auto;

    margin-bottom: unset;
  }
}

.currentPriceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-area: ratePrice;

  padding-bottom: var(--b-space-l);

  &.isSingleRoom {
    @media #{$onlyMobileAndTablet} {
      @include fullWidthContainer;
      @include fullWidthContainerLateralPadding;

      position: sticky;
      z-index: calc(var(--b-z-index-3));
      bottom: calc(var(--b-space-xs) * -1);

      border-top: solid #{toRem(1)} var(--b-color-divider);
      padding-top: var(--b-space-xs);

      background-color: var(--b-color-bg-white);
    }
  }
}
