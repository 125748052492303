$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  @media #{$fromLaptop} {
    overflow: hidden;

    width: 100%;
  }

  overflow: hidden;

  width: 100%;
}

.hotels {
  position: relative;

  display: flex;
  gap: var(--b-space-l);

  padding: var(--b-space-m) 0;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.hotels::-webkit-scrollbar {
  border-radius: var(--b-space-xs);
  height: var(--b-space-xs);

  background: var(--b-color-interactive-primary-hover);
}

.hotels::-webkit-scrollbar-thumb {
  border-radius: var(--b-space-xs);

  background: var(--b-color-interactive-secondary-enabled);
}

.hotels::-webkit-scrollbar-track {
  background: transparent;
}

.hotels > div {
  flex-shrink: 0;

  scroll-snap-align: start;
  scroll-snap-stop: normal;
}
