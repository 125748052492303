$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.toggle {
  position: relative;

  border: none;
  border-radius: var(--b-border-radius-100);
  width: toRem(48);
  height: toRem(24);

  background-color: var(--b-color-field-disabled);
  transition: background-color 0.3s;
  cursor: pointer;
}

.checked {
  background-color: var(--b-color-interactive-primary-enabled);
}

.toggleCircle {
  position: absolute;
  top: toRem(2);
  right: toRem(26);

  border-radius: var(--b-border-radius-50-percent);
  width: toRem(20);
  height: toRem(20);

  background-color: var(--b-color-bg-white);
  transition: right 0.3s;

  [dir='rtl'] & {
    right: toRem(2);
    left: auto;
  }
}

.checked .toggleCircle {
  right: toRem(2);

  [dir='rtl'] & {
    right: toRem(26);
  }
}
