$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  padding: var(--b-space-m);
}

.coupontTypeContainer {
  margin-top: var(--b-space-m);
  border: toRem(1) solid var(--b-color-fill-neutral-02);
  border-radius: var(--b-border-radius-8);
  padding: var(--b-space-m);
}

.appliedCouponsContainer {
  margin-top: var(--b-space-m);
  border: toRem(1) solid var(--b-color-fill-neutral-02);
  border-radius: var(--b-border-radius-8);
  padding: var(--b-space-m);

  background-color: var(--b-color-bg-light);
}
