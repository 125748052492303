$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.loaderContainer {
  height: 100%;
}

.buttonWrapper {
  position: sticky;
  bottom: 0;
  left: 0;

  box-shadow: 0 toRem(-2) toRem(8) 0 rgba(0, 0, 0, 0.10);
  border-top: toRem(1) solid var(--b-color-fill-neutral-02);
  width: 100%;
  padding: var(--b-space-m);

  background-color: var(--b-color-fill-neutral-01);

  text-align: end;
}
