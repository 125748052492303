$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mixins/text';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

.container {
  position: relative;

  display: flex;
  flex-direction: column;
}

.iconWrapper {
  position: absolute;
  top: #{toRem(24)};
  right: #{toRem(12)};

  cursor: pointer;

  [dir='rtl'] & {
    right: unset;
    left: #{toRem(12)};
  }
}

.input {
  @include textField;
}

.inputError {
  @include textField;

  box-shadow: 0 0 0 toRem(1) var(--b-color-support-error-dark) inset;
}

.inputValid {
  @include textField;

  box-shadow: 0 0 0 #{toRem(1)} var(--b-color-support-success) inset;
}

.helperText {
  margin-left: var(--b-space-xs);

  [dir='rtl'] & {
    margin-left: unset;
    margin-right: var(--b-space-xs);
  }
}
