$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  @media #{$onlyDesktop} {
    @include gridLayout;
  }
}

.bodyContent {
  @media #{$fromLaptop} {
    margin-top: unset;
    margin-bottom: var(--b-space-xl);
  }

  @media #{$onlyDesktop} {
    grid-column: 1 / span 9;
  }
}

.asideContainer {
  padding-bottom: var(--b-space-l);

  @media #{$fromLaptop} {
    margin-top: unset;
  }

  @media #{$onlyDesktop} {
    grid-column: 10 / -1;

    padding-bottom: var(--b-space-4xl);
  }
}

.asideButton {
  border: none;
  padding: 0;

  background: none;
  cursor: pointer;

  &:hover:enabled {
    border: none;

    background: none;
  }
}

.asideContent {
  @media #{$fromLaptop} {
    position: sticky;
    top: var(--b-space-m);
  }
}

.bookingStepperMobileWrapper {
  padding-bottom: var(--b-space-xxs);
}

.rooms {
  display: flex;
  gap: var(--b-space-l);
  flex-direction: column;

  margin-bottom: var(--b-space-3xl);

  @media #{$fromLaptop} {
    margin-bottom: var(--b-space-4xl);

    background-color: var(--b-color-bg-light);
  }
}

.roomTypeFilterContainer {
  flex: 1;
  @media #{$onlyDesktop} {
    flex: 2;
    // El combo debe ocupar aprox. la mitad de la card y luego dejar espacio para el botón de borrado de filtro. Sólo cuando no hay banner de ventajas
    max-width: 70%;
  }
}

.filterSectionWrapper {
  display: flex;
  gap: var(--b-space-m);
  flex-direction: column;

  margin-bottom: var(--b-space-m);

  @media #{$fromTablet} {
    margin-bottom: var(--b-space-l);
  }

  @media #{$fromLaptop} {
    display: grid;
    gap: var(--b-space-l);
  }

  @media #{$onlyLaptop} {
    grid-template-columns: repeat(11, 1fr);
  }

  @media #{$onlyDesktop} {
    grid-column: 1 / span 12;
    grid-template-columns: repeat(12, 1fr);

    margin-top: var(--b-space-m);
  }
}

.filterWrapper {
  margin-top: var(--b-space-m);
  width: 100%;
  min-width: toRem(309);

  @media #{$fromTablet} {
    align-self: start;

    margin-top: var(--b-space-2xl);
  }

  @media #{$fromLaptop} {
    align-self: end;
    grid-column: 1 / 5;

    margin-top: unset;
  }

  @media #{$onlyDesktop} {
    grid-column: 1 / 6;
  }
}

.hotelverseWrapper {
  min-width: toRem(309);

  @media #{$onlyLaptop} {
    grid-column: 6 / 12;
  }

  @media #{$onlyDesktop} {
    grid-column: 7 / 13;
  }
}

.title {
  margin-bottom: var(--b-space-xxs);

  @media #{$onlyDesktop} {
    margin-bottom: var(--b-space-l);
  }
}

.heroContainer {
  @include fullWidthContainer;
  @include fullWidthContainerLateralPadding;

  padding-top: var(--b-space-xs);
  padding-bottom: var(--b-space-l);

  background-color: var(--b-color-fill-neutral-00);

  @media #{$fromTablet} {
    padding-top: var(--b-space-m);
  }

  @media #{$onlyLaptop} {
    margin-bottom: var(--b-space-xl);
  }

  @media #{$onlyDesktop} {
    padding-top: var(--b-space-xl);

    background-color: unset;
  }
}
