$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: #{toRem(1)} solid var(--b-color-fill-neutral-02);
  padding: var(--b-space-m);

  @media #{$onlyMobile} {
    gap: var(--b-space-xs);
    flex-direction: column;
    align-items: normal;
  }
}

.button {
  max-height: toRem(40);
  padding: toRem(8) toRem(32);

  color: var(--b-color-button-secondary-enabled);

  &:hover:enabled {
    box-shadow: 0 0 0 #{toRem(3)} var(--b-color-button-secondary-enabled) inset;
  }
}

.price {
  margin-right: var(--b-space-xxs);
}
